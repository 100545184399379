import React, { useState } from "react"
import HTML from "../components/HTML"
import Layout from '../components/layout'
import SEO  from '../components/SEO'
import VDPRecommended from "../components/vdp/VDPRecommended"
import '../scss/srp-side-style.scss'
import '../scss/srp-fonts.scss'
import '../scss/srp-normalize.scss'
function InventoryHTML(props) {
  const { pageContext } = props;
  const { pageContent, theme, dealerInfo, headerTags, vehicle,decimalSpaceHide,ShowLocationFilter,strikeMSRP  } = pageContext;
  const { vdpslug,vehiclecardbutton, unavailableImg, fallbackText, useCompactCards, AutoCheckEnabled, retailPriceCustomText, retailPriceDiscountCustomText, MSRPCustomText } = pageContext.inventorymanagement;
  const [extraData, setExtra] = useState({})

  let components = [ 
    <div className="vehicle-card-containter" data={pageContent.InventoryCards}>
      <VDPRecommended data={pageContent.InventoryCards} recommendation={vehicle} slug={vdpslug}
      buttons={vehiclecardbutton} unavailableImg={unavailableImg} AutoCheckEnabled={AutoCheckEnabled} retailPriceCustomText={retailPriceCustomText} retailPriceDiscountCustomText={retailPriceDiscountCustomText} MSRPCustomText={MSRPCustomText}
      catchActionData={setExtra} fallbackText={fallbackText} ShowLocationFilter={ShowLocationFilter} decimalSpaceHide={decimalSpaceHide} strikeMSRP={strikeMSRP} useCompactCards={useCompactCards}/>
    </div>
  ]
    if (typeof window !== 'undefined'){
        window.DMFilteredInventory = vehicle;
    }
  if(pageContent.hasOwnProperty("ArrayRawHTML")) {
    const { arrayRawHTML } = pageContent.ArrayRawHTML
    const rawComponents = arrayRawHTML.map(element => {  
        return <HTML data={{ 
            order: Number(element.order),
            visible: element.visible,
            rawHTML: element.rawHtml
        }} key={"HTML"} />
    })
    components.push(...rawComponents)
  }
  let sortedComponents = components.sort((a, b) =>
    parseFloat(a.props.data.order) - parseFloat(b.props.data.order)
  ) // sorts the components based on json data
  // filters out the components that are not visible
  let visibleComponents = sortedComponents.filter(component => {
    return component.props.data.visible === true
  })
  return (
    <Layout>
      <HTML key={"HTML"} data={pageContent.HTML} theme={theme} dealer={dealerInfo} />
      {visibleComponents}
      <SEO key={"SEO"} title={"Contact"} data={pageContent.SEO} tagString={headerTags} />
    </Layout>
  )
}
export default InventoryHTML